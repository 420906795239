<template>
    <div class="social__wrapper">
        <a href="https://www.facebook.com/cityrushfood" target="_blank" class="icon__wrapper facebook">
            <FacebookSvg />
        </a>
        <a href="https://www.instagram.com/cityrushfood" target="_blank" class="icon__wrapper instagram">
            <InstagramSvg />
        </a>
    </div>
</template>

<script>
    import InstagramSvg from '@/assets/icons/socials/instagram.svg?inline';
    import FacebookSvg from '@/assets/icons/socials/facebook-f.svg?inline';
    export default {
        name: 'SocialIconsBlock',
        components: { InstagramSvg, FacebookSvg },
    };
</script>

<style lang="scss" scoped>
    .social__wrapper {
        @include row-align-center;

        .icon__wrapper {
            @include row-align-center-justify-center;

            border-radius: $br-100;
            box-shadow: $box-shadow-dark;

            padding: 10px;

            &.instagram {
                background: linear-gradient(
                    45deg,
                    #ffd521 14.64%,
                    #ffd020 18.54%,
                    #fec01e 23.42%,
                    #fca71b 28.82%,
                    #fa8316 34.59%,
                    #f85510 40.67%,
                    #f51e09 46.91%,
                    #f30005 50%,
                    #f20007 50.25%,
                    #e1003b 56.83%,
                    #d30067 63.29%,
                    #c70088 69.49%,
                    #bf00a0 75.38%,
                    #bb00af 80.81%,
                    #b900b4 85.36%
                );
                margin-left: 7px;
            }

            &.facebook {
                background: #3b5998;
                margin-right: 7px;
            }
        }
    }
</style>
