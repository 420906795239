<template>
    <div class="contacts">
        <h2>{{ $t('navbar.contacts') }}</h2>
        <GetInTouchSection>
            <template #requisites>
                <div class="requisites">
                    <h3>{{ $t('contacts.companyRequisites') }}</h3>
                    <h5>{{ $t('contacts.companyCode') }}: <span>304467164</span></h5>
                    <h5>
                        {{ $t('contacts.companyAddress') }}: <span>Dariaus ir Girėno g. 173, LT-02189 Vilnius</span>
                    </h5>
                    <h5>{{ $t('contacts.socialInsuranceCode') }}: <span>2938817</span></h5>
                </div>
            </template>
        </GetInTouchSection>
        <div class="footer-info">
            <h3>{{ $t('contacts.kitDeliveriesAvailableIn') }}</h3>
            <h5 v-for="city in cities" :key="city.id">
                {{ city.name }}
            </h5>
            <h3>{{ $t('contacts.followUs') }}</h3>
            <SocialIconsBlock />
        </div>
    </div>
</template>

<script>
    import SocialIconsBlock from '@/components/Common/SocialIconsBlock/SocialIconsBlock.vue';
    import GetInTouchSection from '@/components/MainSections/GetInTouchSection.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'Contacts',
        components: { GetInTouchSection, SocialIconsBlock },
        computed: {
            ...mapGetters('city', ['cities']),
        },
    };
</script>

<style lang="scss" scoped>
    .contacts {
        padding-top: 25px;
        padding-bottom: 25px;

        h2 {
            width: 100%;
            margin-bottom: 20px;
        }

        .requisites {
            margin-top: 20px;

            h3 {
                margin-bottom: 25px;
            }
        }

        .footer-info {
            margin-top: 24px;

            h3 {
                margin-bottom: 25px;
            }

            h5 {
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 24px;
                }
            }
        }
    }

    @include media($lg) {
        .contacts {
            padding-top: 50px;

            h2 {
                font-size: $font-34;
                margin-bottom: 34px;
            }

            .requisites {
                h3 {
                    font-size: $font-24;

                    margin-bottom: 38px;
                }

                h5 {
                    span {
                        font-weight: 400;
                    }
                }
            }
        }
    }
</style>
